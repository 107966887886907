<template>
  <div id="app">
    <div class="header">
      <div class="left">
        <router-link :to="{ path: '/' }">
          <img src="@/assets/img/logo.png" alt="" />
        </router-link>
      </div>
      <div class="right">
        <span @click.stop="toPaper">{{ $t("whitePaper") }}</span>
        <span @click.stop="toFuturn">{{ $t("future") }}</span>
        <!-- <router-link :to="{ path: '/Planning' }">
          {{ $t("future") }}</router-link
        > -->
        <router-link :to="{ path: '/Announcements' }">
          {{ $t("boardcast") }}</router-link
        >

        <span class="lang" @click.stop="toggleLeng">
          {{ this.lang === "zh" ? "EN" : "CN" }}
        </span>
      </div>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      lang: "zh",
    };
  },
  methods: {
    toggleLeng() {
      if (this.lang === "zh") {
        this.$i18n.locale = this.lang = "en";
      } else {
        this.$i18n.locale = this.lang = "zh";
      }
    },
    toPaper() {
      if (this.$i18n.locale === "zh") {
        window.open("docs/showcoinwhitepaper_cn.pdf", "blank");
      } else {
        window.open("/docs/showcoinwhitepaper_en.pdf", "blank");
      }
    },
    toFuturn() {
      if (this.$i18n.locale === "zh") {
        window.open("/docs/showintroduction_cn.pdf", "blank");
      } else {
        window.open(
          "/docs/Show-Platform-Introduction-and-Future-Planning.pdf",
          "blank"
        );
      }
    },
  },
  computed: {},
};
</script>
<style lang="scss" src="./App.scss">
body,
html {
  // overflow-x: hidden;
  font-family: "ubuntu" !important;
  // box-sizing: border-box;
  user-select: none;
  background-image: url("../assets/img/bg.png");

  width: 100vw;
  // height: 100vh !important;

  ::-webkit-scrollbar {
    display: none;
  }
  ::-moz-scrollbar {
    display: none;
  }
  ::-o-scrollbar {
    display: none;
  }
}
</style>
