<template>
  <div class="homeWrap">
    <div class="content">
      <div class="title">Show You The Future</div>
      <div class="desc">
        <span>ShowFuture Foundation (SFF) is a non-profit organization in</span>
        <span
          >Singapore dedicated to supporting Show Platform development.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
