// import 'lib-flexible/flexible.js'
import "amfe-flexible/index.js";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {} from "element-ui";
import VueI18n from "vue-i18n";
import "./assets/icons/index.js";
import "./assets/reset.css";

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.prototype.$goUrl = (target) => {
  if (target) {
    window.open(target);
  } else {
    alert("Coming Soon");
  }
};
const i18n = new VueI18n({
  locale: "zh", // 定义默认语言为中文
  messages: {
    zh: require("./assets/languages/zh.json"),
    en: require("./assets/languages/en.json"),
  },
});
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
